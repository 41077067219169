@import "./../../index.scss";

footer {
  padding: 64px 0 45px;
  width: 100%;
  min-height: 446px;
  background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/footer.jpg")
    no-repeat;
  background-size: cover;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  @media (max-width: 1042px) {
    padding: 64px 0 75px;
    overflow: hidden;
  }
  @media (max-width: 650px) {
    padding: 64px 0 40px;
  }

  .footerHead {
    color: white;
    text-align: center;
    h4 {
      color: $main-color;
      text-align: center;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      // line-height: 70px;
      letter-spacing: 21.5px;
      text-transform: uppercase;
      @media (max-width: 650px) {
        font-size: 40px;
      }
      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
    p {
      padding: 10px 0 20px;
      // max-width: 811px;
      margin: auto;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 2px;
    }
    .getStarted {
      a {
        padding: 5px 41px;
        color: #ffc700;
        border: 2px solid #ffc700;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 31px; /* 155% */
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    hr {
      opacity: 1;
      margin: auto;
      margin-top: 36px;
      max-width: 80%;
    }
    @media (max-width: 650px) {
      hr {
        // margin: auto;
        // width: 80%;
        // height: 5px;
        background-color: white;
        opacity: 1;
      }
    }
  }

  .footer-start {
    // padding-top: 20px;
    padding: 31px 0;
    .footContent {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // grid-template-columns: repeat(auto-fit, minmax(280px, 500px));
      align-items: center;
      justify-content: space-between;
      gap: 20px 10px;
      backdrop-filter: blur(13px);
      color: white;
      // padding: 0 80px;
      border-radius: 15px;
      @media (max-width: 585px) {
        justify-content: center;
        padding: 0 0px;
      }
      .footLeft {
        display: flex;
        flex-wrap: wrap;
        p {
          padding-left: 42px;
        }
        @media (max-width: 767px) {
          // display: none;
          justify-content: center;
          img {
            margin-bottom: 20px;
          }
        }
      }
      p {
        max-width: 436px;
        line-height: 1.4;
        font-size: 20px;
        font-family: Montserrat;
        font-weight: 600;
      }

      ul {
        li {
          list-style: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/footer/Ellipse 60.svg");
          padding-bottom: 11px;
          a {
            color: #fff;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;
          }
        }
      }
      // .footContCenter {
      // }

      .footerRight {
        text-align: center;
        .iconWhats {
          a {
          }
        }
        p {
          padding-top: 26px;
          color: #fff;
          // text-align: center;
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .footIcon {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          // height: 100%;
          @media (min-width: 1357px) {
            // margin-top: 134px;
          }
          a {
            // img {
            //   border: solid 1px $main-color;
            //   border-radius: 50%;
            // }
            svg {
              font-size: 20px;
              width: 20px;
              color: white;
              border: solid 3px $main-color;
              border-radius: 50%;
              padding: 10px;
              box-sizing: content-box !important;
              margin: 5px 10px;
            }
          }
          img {
            // margin: 5px 10px;
          }
          .iconMess {
            // border: none;
            width: 43px;
            // width: 30px;
            // height: 45.019px;
            // border-radius: 0%;
            padding: 5px;
            box-sizing: content-box;
          }
        }
      }
    }
  }
  hr {
    background-color: #fff;
    height: 2px;
    opacity: 1;
    width: 80%;
    margin: auto;
  }
  .copyRight {
    text-align: center;
    p {
      padding-top: 30px;
      color: white;
      text-align: center;
      font-size: 20px;
      font-family: Montserrat;
      font-weight: 400;
      // line-height: 14px;
    }
  }
  @media (max-width: 650px) {
    .copyRight,
    hr {
      // display: none;
      padding: 0px 10px;
    }
  }
}
