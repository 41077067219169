.header {
  background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/01.jpg")
    no-repeat;
  height: calc(100vh - 160px);
  background-size: cover;
  background-position-x: right;
  background-position-y: 70%;
  @media (max-width: 1200px) {
    // background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentabout/Rectangl5456.png")
    // no-repeat;
    // background-size: contain;
    // height: calc(100vh - 420px);
    // height: 600px;
    width: 100%;
  }
  @media (max-width: 1000px) {
    background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 5497.jpg")
      no-repeat;
    background-size: cover;
    // height: calc(100vh - 160px);
    // width: 100vw;
  }

  @media (max-width: 670px) {
    // background-size: contain;
    // height: calc(100vh - 320px);
    height: 700px;
  }

  @media (max-width: 600px) {
    // background-size: contain;
    // height: calc(100vh - 420px);
    height: 640px;
  }

  @media (max-width: 555px) {
    // background-size: contain;
    // height: calc(100vh - 500px);
    height: 590px;
  }

  @media (max-width: 500px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    height: 530px;
  }

  @media (max-width: 460px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    height: 500px;
  }
  @media (max-width: 430px) {
    height: 460px;
  }

  @media (max-width: 410px) {
    // background-size: contain;
    // height: calc(100vh - 150px);
    height: 430px;
    // margin-bottom: 20px;
  }

  // ------------------------------------------

  @media (min-width: 200px) /* and (max-width: 575px) */ {
    // height: calc(100vh - 108px);
  }
  @media (min-width: 768px) {
    background-size: 100%;
    background-position: center;
  }
  @media (min-width: 992px) {
    height: calc(100vh - 160px);
    background-position: center;
  }
  // @media (min-width: 1200px) {}
  // @media (min-width: 1400px) {}

  // @media (min-height: 740px) {}

  /*
  // ------------------------------------------
  // @media (max-width: 1000px) {
  //   height: calc(100vh - 300px);
  // }

  // @media (max-width: 850px) {
  //   height: calc(100vh - 380px);
  // }
  // @media (max-width: 700px) {
  //   height: calc(100vh - 500px);
  // }

  // @media (max-width: 570px) {
  //   height: 320px;
  // }
  // @media (max-width: 480px) {
  //   height: 270px;
  // }
  // @media (max-width: 420px) {
  //   height: 230px;
  // }
  // ------------------------------------------
  */
}

// ------------------------------------------
