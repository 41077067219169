@import "./../../../index.scss";

.about {
  // background: url('./../../../assets/Rectangle 5456.png') no-repeat ;
  // height: calc(100vh - 170px);
  // background-size: cover;
  // background-position-x: right;
  // background-position-y: 70%;
  // @media (max-width: 452px) {
  //   background-position-x: 96%;
  // }
  // background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentabout/Rectangle 5456.png") no-repeat;
  // height: calc(100vh - 160px);
  // background-size: cover;
  // background-position-x: right;
  // background-position-y: 70%;
  // @media (max-width: 452px) {
  //   // background-position-x: 96%;
  // }
  // @media (max-width: 1200px) {
  //   background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentabout/Rectangl5456.png") no-repeat;
  //   background-size: contain;
  //   height: calc(100vh - 220px);
  // }
  // @media (max-width: 1000px) {
  //   height: calc(100vh - 300px);
  // }
  // @media (max-width: 1000px) {
  //   height: calc(100vh - 300px);
  // }

  // @media (max-width: 850px) {
  //   height: calc(100vh - 380px);
  // }
  // @media (max-width: 700px) {
  //   height: calc(100vh - 500px);
  // }

  // @media (max-width: 570px) {
  //   height: 320px;
  // }
  // @media (max-width: 480px) {
  //   height: 270px;
  // }
  // @media (max-width: 420px) {
  //   height: 230px;
  // }

  background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Rectangle 5456.jpg")
    no-repeat;
  height: calc(100vh - 160px);
  background-size: cover;
  background-position-x: right;
  background-position-y: 70%;
  @media (max-width: 452px) {
    // background-position-x: 96%;
  }
  @media (max-width: 1200px) {
    // background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentabout/Rectangl5456.png")
    //   no-repeat;
    // background-size: cover;
    // height: calc(100vh - 220px);
    // height: 600px;
    // width: 100%;
  }

  @media (max-width: 870px) {
    background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 5497.jpg") no-repeat;
    background-size: cover;
    height: calc(100vh - 100px);
    // width: 100vw;
  }
  @media (min-width: 848px) and (max-width: 1000px) {
    // margin-bottom: 50px;
  }
 
  @media (max-width: 880px) {
    // background-size: contain;
    // height: calc(100vh - 160px);
  }

  @media (max-width: 830px) {
    // background-size: contain;
    // height: calc(100vh - 170px);
  }

  @media (max-width: 767px) {
    // background-size: contain;

    // height: calc(100vh - 250px);
  }

  @media (max-width: 670px) {
    // background-size: contain;
    // height: calc(100vh - 320px);
    height: 653px;
  }

  @media (max-width: 600px) {
    // background-size: contain;
    // height: calc(100vh - 420px);
    height: 592px;
  }

  @media (max-width: 555px) {
    // background-size: contain;
    // height: calc(100vh - 500px);
    height: 505px;
  }

  @media (max-width: 500px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    height: 436px;
  }

  @media (max-width: 460px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    // height: 500px;
  }
  @media (max-width: 430px) {
    // height: 460px;
  }

  @media (max-width: 410px) {
    // background-size: contain;
    // height: calc(100vh - 150px);
    height: 387px;
    // margin-bottom: 20px;
  }
}
