@import "./../../../index.scss";

.container {
  // background-color: rgb(85, 85, 85);
  @media (max-width: 1536px) {
  }
  //  max-width: 1320px !important;
}

.homeHeader {
  // padding-top: 60px;

  @media (max-width: 1080px) {
    padding-top: 0px;
  }
  .homeContainer {
    overflow: hidden;
    .home {
      // background-color: #aaa !important;
      margin-top: 150px;
      background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/02.png")
        no-repeat;
      background-size: contain;
      // height: 70vh;

      background-size: cover;
      height: 100vh;
      width: 100%;

      h5 {
        // color: #000;
        color: white;
        font-family: Montserrat;
        font-size: 128px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        position: relative;
        top: -70px;
      }

      .homeImgs {
        span {
          // -webkit-text-fill-color: transparent;
          // -webkit-text-stroke-width: 2px;
        }

        span {
          display: flex;
          justify-content: center;
          // align-items: center;
          margin-top: 50px;
          img {
            // text-align: center;
            // width: 350px;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
          }
        }
      }

      @media (max-width: 767px) {
        // background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/homemopile.png")
        //   no-repeat;
        background-size: contain;
        // height: 40vh;
      }

      @keyframes animationLeft {
        // 0% {
        //   opacity: 0.3;
        //   transform: translateX(-250px) scale(0.9);
        // }
        // 30% {
        //   opacity: 0.4;
        //   transform: translateX(-150px) scale(0.95) rotate(-2deg);
        // }
        // 50% {
        //   opacity: 0.6;
        //   transform: translateX(-100px) scale(1) rotate(0deg);
        // }
        // 75% {
        //   opacity: 0.8;
        //   transform: translateX(-50px) scale(0.97) rotate(2deg);
        // }
        // 100% {
        //   opacity: 1;
        //   transform: translateX(0px) scale(0.9);
        // }

        // 0% {
        //   opacity: 0.7;
        //   transform: translateX(-100%);
        // }
        // 100% {
        //   opacity: 1;
        //   transform: translateX(100%);
        // }

        0% {
          transform: translateX(-100%);
          opacity: 0.7;
        }
        35% {
          transform: translateX(0);
          opacity: 0.85;
        }
        65% {
          transform: translateX(0);
          opacity: 0.85;
        }
        100% {
          transform: translateX(100%);
          opacity: 1;
        }
      }

      .animationLeft {
        // animation: animationLeft 2s  infinite alternate;
        // will-change: opacity, transform;

        animation: animationLeft 10s linear infinite;
        will-change: opacity, transform;
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // overflow: hidden;
        // white-space: nowrap;
      }

      @keyframes animationRight {
        // 30% {
        //   opacity: 0.4;
        //   transform: translateX(150px) scale(0.95) ;
        // }
        // 50% {
        //   opacity: 0.6;
        //   transform: translateX(100px) scale(1);
        // }
        // 75% {
        //   opacity: 0.8;
        //   transform: translateX(50px) scale(0.97);
        // }
        // 90% {
        //   opacity: 0.9;
        //   transform: translateX(0px) scale(0.97);
        // }
        // 0% {
        //   opacity: 0.7;
        //   transform: translateX(100%);
        // }
        // 100% {
        //   opacity: 1;
        //   transform: translateX(-100%);
        // }
        0% {
          transform: translateX(100%);
          opacity: 0.7;
        }
        35% {
          transform: translateX(0);
          opacity: 0.85;
        }
        65% {
          transform: translateX(0);
          opacity: 0.85;
        }
        100% {
          transform: translateX(-100%);
          opacity: 1;
        }
      }

      .animationRight {
        animation: animationRight 10s linear infinite;
        will-change: opacity, transform;
      }

      .animationLeft.active ~ .homeImgs span img,
      .animationRight.active ~ .homeImgs span img {
        opacity: 1;
      }

      /* 

      // @media (min-width: 900px) {
      //   .bagImg1 {
      //     position: absolute;
      //     background: url("./../../../assets/Rectang0.png") no-repeat;
      //     background-size: cover;
      //     height: 57vh;
      //     width: 800px;
      //     top: 120px;
      //     // left: -80px;
      //     z-index: -1;
      //   }
      //   .bagImg2 {
      //     position: absolute;
      //     background: url("./../../../assets/Rectangle 88.png") no-repeat;
      //     height: 85vh;
      //     width: 100%;
      //     left: 170px;
      //   }
      // }
    
      // @media (min-width: 1481px) {
      //   .bagImg1 {
      //     // height: 95vh;
      //     left: 90px;
      //   }
      //   .bagImg2 {
      //     height: 85vh;
      //     left: 480px;
      //   }
      //   h6 {
      //     // width: 100%;
      //     width: max-content;
      //   }
      //   p {
      //     width: max-content;
      //   }
      // }
    
      // @media (max-width: 1480px) {
      //   .bagImg1 {
      //     // height: 65vh;
      //     // left: 10px;
      //   }
      //   .bagImg2 {
      //     // height: 65vh;
      //     left: 310px;
      //   }
    
      //   h5 {
      //     top: 5px;
      //     left: -30px;
      //   }
      //   h6 {
      //     top: 240px;
      //     left: 180px;
      //   }
      //   p {
      //     top: 480px;
      //     left: 550px;
      //   }
      // }
    
      // @media (max-width: 1480px) {
      //   .bagImg1 {
      //     // height: 65vh;
      //     // left: 10px;
      //   }
      //   .bagImg2 {
      //     // height: 65vh;
      //     left: 210px;
      //   }
      // }
    
      // @media (max-width: 1211px) {
      //   .bagImg1 {
      //     // height: 65vh;
      //     // left: -100px;
      //   }
      //   .bagImg2 {
      //     // height: 65vh;
      //     top: -10px;
      //     left: 150px;
      //   }
      //   h5 {
      //     z-index: 1;
      //     // top: 5px;
      //     // left: -30px;
      //   }
      //   h6 {
      //     // top: 240px;
      //     // left: 180px;
      //   }
      //   p {
      //     // top: 480px;
      //     // left: 550px;
      //   }
      // }
    
      // @media (max-width: 1151px) {
      //   .bagImg1 {
      //     // height: 65vh;
      //     // left: -100px;
      //   }
      //   .bagImg2 {
      //     // height: 65vh;
      //     top: -20px;
      //     left: 100px;
      //   }
      // }
    
      // @media (max-width: 1100px) {
      //   .bagImg1 {
      //     height: 50vh;
      //     top: 140px;
      //     // left: -100px;
      //   }
      //   .bagImg2 {
      //     // height: 65vh;
      //     top: -50px;
      //     left: 50px;
      //   }
      //   h5 {
      //     font-size: 100px;
      //     top: 45px;
      //     left: -90px;
      //   }
      //   h6 {
      //     font-size: 100px;
      //     top: 260px;
      //     left: 90px;
      //   }
      //   p {
      //     top: 420px;
      //     left: 400px;
      //   }
      // }
    
      // @media (max-width: 1050px) {
      //   .bagImg1 {
      //     // left: -130px;
      //   }
      //   .bagImg2 {
      //     top: -70px;
      //     left: -10px;
      //   }
      //   h6 {
      //     font-size: 100px;
      //     top: 260px;
      //     left: 200px;
      //   }
      // }
    
      // @media (max-width: 1039px) {
      //   .bagImg2 {
      //     // left: -30px;
      //   }
      // }
    
      
      // //mopile
      
      //   .bagImg2 {
      //     position: absolute;
      //     background: url("./../../../assets/Rectangle1 88.png") no-repeat;
      //     background-size: contain;
      //     width: 800px;
      //     height: 100vh;
      //     left: 150px;
      //   }
    
      //   h5 {
      //     font-size: 70px;
      //     top: 135px;
      //     left: -140px;
      //   }
      //   h6 {
      //     font-size: 70px;
      //     top: 260px;
      //     // left: -60px;
      //   }
      //   p {
      //     font-size: 60px;
      //     // color: red;
      //     top: 350px;
      //     left: 450px;
      //   }
      // }
    
      // @media (max-width: 950px) {
      //   .bagImg1 {
      //     // width: 400px;
      //     left: 10px;
      //   }
      //   .bagImg2 {
      //     width: 700px;
      //     top: 10px;
      //     left: 100px;
      //   }
      //   h5 {
      //     // font-size: 70px;
      //     // top: 135px;
      //     left: -180px;
      //   }
      //   h6 {
      //     // font-size: 70px;
      //     // top: 260px;
      //     left: 130px;
      //   }
      // }
    
      // @media (max-width: 899px) {
      //   .bagImg1 {
      //     // top: 200px;
      //   }
      // }
    
      // @media (max-width: 800px) {
      //   .bagImg2 {
      //     left: 10px;
      //   }
    
      //   h5 {
      //     font-size: 70px;
      //     top: 135px;
      //     left: -120px;
      //   }
      //   h6 {
      //     // font-size: 70px;
      //     // top: 260px;
      //     // left: -190px;
      //   }
      //   p {
      //     font-size: 60px;
      //     // color: red;
      //     top: 370px;
      //     left: 300px;
      //   }
      // }
    
      // @media (max-width: 710px) {
      //   .bagImg1 {
      //     top: 60px;
      //   }
      //   .bagImg2 {
      //     left: 80px;
      //     top: 0px;
      //     width: 500px;
      //   }
    
      //   h5 {
      //     font-size: 40px;
      //     top: 22px;
      //     left: -140px;
      //   }
      //   h6 {
      //     font-size: 40px;
      //     top: 150px;
      //     left: 120px;
      //   }
      //   p {
      //     font-size: 60px;
      //     // color: red;
      //     top: 230px;
      //     left: 250px;
      //   }
      // }
    
      // @media (max-width: 580px) {
      //   .bagImg1 {
      //     // background-size: cover;
      //     // height: 50vh;
      //     top: 30px;
      //   }
      //   .bagImg2 {
      //     // left: 80px;
      //     // top: 170px;
      //     width: 450px;
      //   }
    
      //   h5 {
      //     font-size: 40px;
      //     top: -10px;
      //     left: -70px;
      //   }
      //   h6 {
      //     font-size: 40px;
      //     top: 130px;
      //     left: 110px;
      //   }
      //   p {
      //     font-size: 38px;
      //     // color: red;
      //     top: 230px;
      //     left: 250px;
      //   }
      // }
    
      // @media (max-width: 530px) {
      //   .bagImg1 {
      //     // top: 250px;
      //     width: 400px;
      //   }
      //   .bagImg2 {
      //     top: -20px;
      //     width: 400px;
      //   }
    
      //   h5 {
      //     font-size: 40px;
      //     top: -10px;
      //     left: -70px;
      //   }
      //   h6 {
      //     font-size: 40px;
      //     top: 130px;
      //     left: 100px;
      //   }
      //   p {
      //     font-size: 38px;
      //     // color: red;
      //     top: 180px;
      //     // left: 250px;
      //   }
      // }
    
      // @media (max-width: 480px) {
      //   .bagImg1 {
      //     // top: 250px;
      //     width: 350px;
      //   }
      //   .bagImg2 {
      //     // top: 198px;
      //     width: 350px;
      //   }
    
      //   h5 {
      //     // font-size: 40px;
      //     // top: -10px;
      //     // left: -70px;
      //   }
      //   h6 {
      //     font-size: 40px;
      //     top: 80px;
      //     left: 80px;
      //   }
      //   p {
      //     font-size: 38px;
      //     // color: red;
      //     top: 150px;
      //     left: 230px;
      //   }
      // }
    
      // @media (max-width: 430px) {
      //   .bagImg1 {
      //     // top: 250px;
      //     width: 330px;
      //   }
      //   .bagImg2 {
      //     // top: 198px;
      //     width: 330px;
      //   }
      //   h5 {
      //     // font-size: 40px;
      //     // top: -10px;
      //     left: -40px;
      //   }
      //   h6 {
      //     // font-size: 40px;
      //     // top: 80px;
      //     left: 80px;
      //   }
      //   p {
      //     font-size: 35px;
      //     // color: red;
      //     top: 140px;
      //     left: 210px;
      //   }
      // }
    
      // @media (max-width: 410px) {
      //   .bagImg1 {
      //     // top: 250px;
      //     width: 300px;
      //   }
      //   .bagImg2 {
      //     // top: 198px;
      //     width: 300px;
      //   }
      //   h5 {
      //     // font-size: 40px;
      //     // top: -10px;
      //     left: -40px;
      //   }
      //   h6 {
      //     // font-size: 40px;
      //     // top: 80px;
      //     left: 70px;
      //   }
      //   p {
      //     // width: 43%;
      //     // font-size: 35px;
      //     // color: red;
      //     top: 122px;
      //     left: 200px;
      //   }
      // }
      // }
      */
    }
  }

  @media (min-width: 200px) /* and (max-width: 575px) */ {
    .homeContainer {
      .home {
        margin-top: 0px;
        background-size: 100%;
        // background-size: contain;
        height: 30vh;
        // height: 200px;
        // background-position-y: center;

        h5 {
          // font-size: 100%;
          font-size: 30px;
          top: 35px;
          left: 5%;
        }

        .homeImgs {
          position: relative;
          h6 {
            width: 100%;
            position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: -10px;
            left: 35%;
            img {
              width: 220px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 16%;
              margin-left: 50%;

              width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 520px) {
    .homeContainer {
      .home {
        // margin-top: 0px;
        // background-size: 100%;
        // background-size: contain;
        // height: 30vh;
        height: 310px;
        // background-position-y: center;

        h5 {
          // font-size: 100%;
          font-size: 35px;
          top: 45px;
          left: 15%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: 0px;
            // left: 35%;
            img {
              width: 235px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              // margin-top: 16%;
              // margin-left: 50%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 650px) {
    .homeContainer {
      .home {
        // margin-top: 0px;
        // background-size: 100%;
        // background-size: contain;
        // height: 30vh;
        height: 370px;
        // background-position-y: center;

        h5 {
          // font-size: 100%;
          // font-size: 35px;
          // top: 45px;
          // left: 15%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            // top: 0px;
            // left: 35%;
            img {
              // width: 235px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              // margin-top: 16%;
              // margin-left: 50%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .homeContainer {
      .home {
        // margin-top: 70px;
        // background-size: 100%;
        // height: 50vh;
        height: 430px;
        // background-position: center;

        h5 {
          font-size: 45px;
          // font-size: 100%;
          top: 75px;
          // left: 5%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: 30px;
            // left: 5%;
            img {
              width: 260px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 17%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .homeContainer {
      .home {
        // margin-top: 70px;
        // background-size: 100%;
        // height: 65vh;
        height: 630px;
        // background-position: center;

        h5 {
          font-size: 60px;
          top: 105px;
          // left: 20%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: 14%;
            left: 38%;
            img {
              width: 350px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 26%;
              margin-left: 45%;
              
              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .homeContainer {
      .home {
        // margin-top: 70px;
        // background-size: 100%;
        // height: 83vh;
        height: 730px;
        // background-position: center;

        h5 {
          font-size: 70px;
          // top: -20px;
          top: 125px;
          // left: 20%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: 18%;
            left: 38%;

            img {
              width: 400px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 26%;
              margin-left: 45%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .homeContainer {
      .home {
        // margin-top: 90px;
        // background-size: auto;
        // background-size: cover;
        // height: 100vh;
        height: 840px;
        // background-position: center;

        h5 {
          // font-size: 100px;
          top: 155px;
          // left: 20%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            top: 20%;
            // top: 130px;
            // left: 25%;

            img {
              width: 410px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 28%;
              margin-left: 45%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1600px) {
    .homeContainer {
      .home {
        // margin-top: 90px;
        // background-size: auto;
        // background-size: cover;
        // height: 100vh;
        height: 920px;
        // background-position: center;

        h5 {
          // font-size: 100px;
          top: 185px;
          left: 20%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            // top: 20%;
            // top: 130px;
            // left: 25%;

            img {
              // width: 410px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              // margin-top: 28%;
              // margin-left: 45%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1780px) {
    .homeContainer {
      .home {
        // margin-top: 90px;
        // background-size: auto;
        // background-size: cover;
        // height: 100vh;
        height: 1030px;
        // background-position: center;

        h5 {
          font-size: 100px;
          top: 200px;
          // left: 20%;
        }

        .homeImgs {
          // position: relative;
          h6 {
            width: 100%;
            // position: absolute;
            // text-align: center;
            // margin-left: 5%;
            // top: 20%;
            // top: 130px;
            // left: 25%;

            img {
              width: 560px;
            }
          }
          span {
            // bottom: -10%;
            img {
              // position: absolute;
              // top: 55% !important;
              // left: 55% !important;
              // bottom: -150px;
              // font-size: 20px;
              margin-top: 25%;
              // margin-left: 45%;

              // width: 40%;
              // display: none;
            }
          }
        }
      }
    }
  }


  @media (min-height: 740px) {
    .homeContainer {
      .home {
        // height: 750px;
      }
    }
  }
}
