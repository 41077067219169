@import "./../../../index.scss";

.servicesHead {
  // border-bottom: #000 solid 5px;
  padding-top: 27%;
  h1 {
    padding-bottom: 20px;
    color: $main-color;
    font-size: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }
  h6 {
    // padding-bottom: 20px;
    max-width: 1000px;
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 991px) {
    padding-top: 190px;
  }
  background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/bright-shot-new-buildings-neighborhood 1.jpg")
    no-repeat;
  background-size: cover;
  height: calc(100vh - 170px);
  background-size: cover;
  // background-position-x: right;
  // background-position-y: 70%;
  @media (max-width: 452px) {
    // background-position-x: 96%;
  }

  @media (max-width: 900px) {
    background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/bright-shot-new-buildings-neighborhood 1.jpg")
      no-repeat;
    background-size: cover;
    // height: calc(100vh - 110px);

    // width: 100vw;
  }
  // @media (min-width: 760px) and (max-width: 1000px) {
  //   margin-bottom: 50px;
  // }


  @media (max-width: 670px) {
    // background-size: contain;
    // height: calc(100vh - 320px);
    height: 700px;
  }

  @media (max-width: 600px) {
    // background-size: contain;
    // height: calc(100vh - 420px);
    height: 640px;
  }

  @media (max-width: 555px) {
    // background-size: contain;
    // height: calc(100vh - 500px);
    height: 590px;
  }

  @media (max-width: 500px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    height: 530px;
  }

  @media (max-width: 460px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    height: 500px;
  }
  @media (max-width: 430px) {
    height: 460px;
  }

  @media (max-width: 410px) {
    // background-size: contain;
    // height: calc(100vh - 150px);
    height: 430px;
    // margin-bottom: 20px;
  }


  @media (min-width: 200px){
    padding-top: 25%;
    background-size: 100%;
    background-position: center;
    h1 {
      font-size: 45px;
      text-align: center;
    }
    h6 {
      text-align: center;
    }
  }

  @media (min-width: 768px){
    height: 750px;
    padding-top: 27%;
    background-size: 100%;
    background-position: center;
    h1 {
      font-size: 50px;
      text-align: center;
    }
    h6 {
      // text-align: center;
    }
  }

  @media (min-width: 900px){
    padding-top: 200px;
    // height: 485px;
    // height: calc(100vh - 300px);
    height: calc(100vh - 170px);
    background-size: 100%;
    background-size: cover;
    background-position: center;
    h1 {
      // font-size: 50px;
      // text-align: center;
    }
    h6 {
      // text-align: center;
    }
  }




  
  //------------------------------

  @media (max-width: 767px) {
    // background-size: contain;

    // height: calc(100vh - 250px);
  }

  @media (max-width: 670px) {
    // background-size: contain;
    // height: calc(100vh - 320px);
    // height: 700px;
  }

  @media (max-width: 600px) {
    // background-size: contain;
    // height: calc(100vh - 420px);
    // height: 640px;
  }

  @media (max-width: 555px) {
    // background-size: contain;
    // height: calc(100vh - 500px);
    // height: 590px;
  }

  @media (max-width: 500px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    // height: 530px;
  }

  @media (max-width: 460px) {
    // background-size: contain;
    // height: calc(100vh - 570px);
    // height: 500px;
  }
  @media (max-width: 430px) {
    // height: 460px;
  }

  @media (max-width: 410px) {
    // background-size: contain;
    // height: calc(100vh - 150px);
    // height: 430px;
    // margin-bottom: 20px;
  }

  
}
