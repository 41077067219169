@import "./../../../index.scss";

.projects {
  padding: 100px 0;

  @media (min-width: 768px) {
    background: url("https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Vector.png")
      no-repeat;
  }
  background-size: contain;
  // height: 100vh;
  background-position: 100% 0;
  @media (max-width: 981px) {
    background-size: cover;
  }

  // @media (max-width: 768px) {
  //   background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Vector2.png")
  //     no-repeat;
  //   // background-size: cover;
  //   // background-position: 100% 0;
  // }
  .categories-buttons {
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    button {
      // color: #ffc700;
      background-color: #d9d9d9;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      // line-height: normal;
      text-transform: capitalize;
      border-radius: 30px;
      padding: 10px 20px;
      outline: none;
      border: none;
    }
    .active {
      color: $main-color;
      background: black;
    }
  }
  .proSlider {
    margin: auto;
    max-width: 950px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    align-items: center;
    justify-content: center;
    gap: 30px;
    .slick-next {
      right: 0px;
      display: none !important;
    }
    .slick-prev {
      display: none !important;
    }

    .slick-dots {
      bottom: 33px;
      width: 100%;
    }
    .slick-dots li button::before {
      color: white;
    }
    .slick-dots li button::before {
      font-size: 19px;
      opacity: 4.05;
    }

    .slick-dots li.slick-active button::before {
      color: $main-color !important;
    }

    .imgSlide {
      // padding-right: 30px;
      img {
        width: 600px;
        height: 600px;
        margin: auto;
      }
    }
    .parentImg {
      position: relative !important;
      width: 100%;

      .slidText {
        position: absolute;
        width: 100%;
        bottom: 60px;
        padding: 15px 30px;
        h5,
        h6 {
          color: #fff;
          font-family: Montserrat;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }

    // @media (min-width: 992px) and (max-width: 1100px) {
    // }
  }

  .proPagination {
    padding-top: 50px;
    img {
      width: 20px;
    }
  }
}
